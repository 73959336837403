<template>
  <div>
    <div class="card card-custom gutter-b mt-5">
      <!--begin::Card header-->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{banque.titre}} <strong>{{total}}</strong></h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-primary mr-1"  v-b-modal.add-detail>
            <i class="icon-md fas fa-plus"></i>
          </a>
        </div>
      </div>
    </div>
    <b-row class="mt-5">
      <b-col md="4" class="mt-2" v-for="(elt,index) in details"  :key="index">
        <b-list-group>
          <b-list-group-item>{{elt.montant}}</b-list-group-item>
          <b-list-group-item>{{elt.commentaire}}</b-list-group-item>
          <b-list-group-item>{{elt.date}}</b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-modal id="add-detail" centered title="Ajouter une entrant" ref="addDetail">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="tmpDetail.commentaire"  required placeholder="Salaire"></b-form-input>
        </b-form-group>
        <b-form-group label="Montant:">
          <b-form-input v-model="tmpDetail.montant"  required placeholder="789466"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="save()" :style="'margin-left:6px'">Ajouter</b-button>
          <b-button variant="danger" size="sm" class="float-right" @click="show=false">Annuler</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      banque:{
        id:null,
        user_id:null,
        titre:null
      },
      details:[],
      tmpDetail:{
        compte_id: this.$route.params.id,
        montant: null,
        commentaire: null
      }
    };
  },
  computed:{
    total(){
      var total = 0;
      this.details.forEach((item) => {
        total += item.montant;
      });
      return total.toFixed(2);
    }
  },
  components: {
  },
  watch: {
  },
  methods: {
    save(){
      this.$store.back.ajax('/banque/details/create', this.tmpDetail, (res) => {
        if(res.status === true){
          this.initDetails();
        }
      })
    },
    initDetails(){
      this.$store.back.ajax('/banque/details/'+this.$route.params.id, null, (res) => {
        if(res.status === true){
          this.banque = res.data.banque;
          this.details = res.data.details;          
          this.$refs.addDetail.hide()
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.initDetails();
  }
};
</script>
